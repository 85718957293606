import Header from '@components/Navigation/Header/Header';
import { createContext, useContext, useState } from 'react';
import { HeaderNavigation } from '@components/Navigation';
import { PageProps } from '~/types/Magnolia';
import useIsomorphicLayoutEffect from '@hooks/useIsomorphicLayoutEffect';

const HeaderContext = createContext<PageProps['page'] | null>(null);
export const useHeaderContext = () => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error('useHeaderContext must be used within HeaderContext');
  }
  return context;
};

export interface HeaderContainerProps {
  mainHeader: HeaderNavigation | null;
  headerProviderScrolledPage: HeaderNavigation | null;
  headerGeoScrolledPage: HeaderNavigation | null;
  headerSem: HeaderNavigation | null;
  isAccountEnabled: boolean;
  page?: PageProps['page'];
}
const HeaderContainer = ({
  mainHeader,
  headerProviderScrolledPage,
  headerGeoScrolledPage,
  headerSem,
  isAccountEnabled,
  page,
}: HeaderContainerProps) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const template = page?.['mgnl:template'];
  const isProviderPage = template === 'spa-lm:pages/provider';
  const isSemPage = template === 'spa-lm:pages/sem';
  const isGeoPage = template === 'spa-lm:pages/geo';
  useIsomorphicLayoutEffect(() => {
    setIsScrolled(window.scrollY > 0);
    const handleScroll = () => setIsScrolled(window.scrollY > 0);

    if (isProviderPage || isGeoPage) {
      document.addEventListener('scroll', handleScroll);
    }

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [isProviderPage, isGeoPage]);

  let header: HeaderNavigation | null = mainHeader;

  const showProviderScrolledHeader: boolean =
    !!headerProviderScrolledPage && isProviderPage && isScrolled;
  const showGeoScrolledHeader: boolean =
    !!headerGeoScrolledPage && isGeoPage && isScrolled;

  if (showProviderScrolledHeader) {
    header = headerProviderScrolledPage;
  } else if (showGeoScrolledHeader) {
    header = headerGeoScrolledPage;
  } else if (isSemPage && headerSem) {
    header = headerSem;
  }

  if (!header) {
    return null;
  }

  return (
    <HeaderContext.Provider value={page || null}>
      <Header
        logo={header.logo}
        main={header.main}
        secondary={header.secondary}
        mainMenuPosition={header.mainMenuPosition}
        showShadow={header.showShadow}
        isAccountEnabled={isAccountEnabled}
        metadata={header.metadata}
        sameBackgroundAsBody={page?.backgroundColor?.headerWithSameColor}
        isScrolledHeader={showProviderScrolledHeader || showGeoScrolledHeader}
        isProviderPage={isProviderPage}
        isGeoPage={isGeoPage}
        isSemPage={isSemPage}
      />
    </HeaderContext.Provider>
  );
};

export default HeaderContainer;
